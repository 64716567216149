<template>
  <teleport to="head">
    <component :is="'script'" type="application/ld+json">
        {
            "@context": "https://schema.org",
            "@type": "NewsArticle",
            "url": "{{newsDetail.urlName}}",
            "publisher":{
                "@type":"Organization",
                "name":"AsiaSport News",
                "logo":"https://asiasport.com/img/icons/favicon-32x32.png"
            },
            "headline": "{{newsDetail.title}}",
            "mainEntityOfPage": "{{newsDetail.urlName}}",
            "articleBody": "{{newsDetail.contentOnly}}",
            "image": {{newsDetail.contentImgArray}},
            "datePublished":"{{newsDetail.originalPublishedOn}}",
            "author":{
                "@type" : "Person",
                "name" : "{{newsDetail.authorName}}"
            }
      }
    </component>
  </teleport>
   <MobileHeader></MobileHeader>
    <div class="container">
        <div class="news-detail__wrapper has-header has-space">
            <div class="news-detail__container">
                <h1 class="news-detail__title">{{newsDetail.title}}</h1>
                <div class="news_detail__author-header__wrapper">
                    <router-link  :to="{ name: 'newsAuthor', params: {authorName:newsDetail.urlAuthorName, id: newsDetail.author}}"  class="news_detail__author-wrapper">
                        <img class="news_detail__author-icon" src="../../../static/images/icons/icon_news_author.png">
                        <div class="news_detail">
                            <span class="news_detail__author-name">{{$t('BY')}} {{newsDetail.authorName}}</span> 
                            <div>
                                <span class="news_detail__author-tag">{{newsDetail.publishedOn}}</span>
                            </div>
                        </div>
                    </router-link>
                    <div class="news_detail__author-header__social-media">
                        <div class="news_detail__author-header__social-media__container" v-for="(o, index) in socialMediaShare" :key="index" @click="handleSocialMedia(o)">
                            <img :src="require(`../../../static/images/icons/social_media/${o.iconUrl}.png`)">
                        </div>
                    </div>
                </div>
                
                <!-- content -->
                <div class="mb-3rem">
                  <!-- <div class='card'>
  <div class="card-content">
    <h1>WOAH!</h1>
    <p>Cool 3D Stuff bro!</p>
  </div>
</div>-->
                    <div class="news-detail__content" v-html="newsDetail.content"></div> 
                      <!-- <div class="news-detail__3d-wrapper">
                          <div class="news-detail__3d news-detail__3d-3"><img src="../../../static/images/3.png"></div>
                        <div class="news-detail__3d news-detail__3d-1"><img src="../../../static/images/1.png"></div>
                        <div class="news-detail__3d news-detail__3d-2"><img src="../../../static/images/2.png"></div>
                    </div> -->
                </div>

               

                <!-- tags -->
                <div class="news-detail__tags-wrapper">
                    <div class="news-detail__tags-title__wrapper">
                        <span>{{$t('TAGS')}}</span>
                    </div>
                    <div class="" >
                        <router-link :to="{ name: 'newsRelated', params: {categoryName: o.urlName, categoryId: o.id}}" v-for="(o, index) in newsDetail.newsTags" :key="index" class="news-detail__tags">{{o.name}}</router-link>
                    </div>
                </div>

                 <!-- comment -->
                <div class="mb-main" v-if="!isMobile">                
                    <comment :isGetData="refreshComment"></comment>
                </div>
            </div>
        
            <div class="related-articles__container" v-show="relatedArticleList.length>0">
                <div class="news_latest-news__title-wrapper">
                    <span class="news_latest-news__title">{{$t('RELATED_ARTICLES')}}</span>
                </div>
                <!-- //related articles -->
                <div  v-if="!isMobile" >
                    <router-link :to="{ name: 'newsDetail', params: { title: o.urlName, id: o.id, currentSportType: currentSportType}}" class="news_latest-news__container" v-for="(o, index) in relatedArticleList" :key="index">
                        <div class="news_latest-news__container-img">
                            <img :src="`${s3ImgUrl}/${o.coverImage}`" class="news_latest-news__container-img__coverImg">
                            <div class="news_latest-news__container-img-icon" v-if="o.videoFlag"><img src="../../../static/images/icons/icon_video_play.png"></div>
                        </div>
                        <div class="news_latest-news__container-desc">
                            <p class="news_latest-news__container-desc__title">{{o.title}}</p>
                            <div>
                                <span class="news_latest-news__container-desc_tag">{{o.publishedOn}}</span>
                            </div>
                        </div>
                    </router-link>  
                </div>
                <div v-else>
                    <swiper  :pagination="{ clickable: true }"  :slidesPerView="2"  :autoplay='{"delay": 5000, "disableOnInteraction": false}'  :spaceBetween="12"  class="relative news__related-articles__swipper">
                        <swiper-slide class="news_latest-news__container" v-for="(o, index) in relatedArticleList" :key="index">
                            <router-link :to="{ name: 'newsDetail',  params: { title: o.urlName, id: o.id, currentSportType: currentSportType}}">
                                <div class="news_latest-news__container-img">
                                    <img :src="`${s3ImgUrl}/${o.coverImage}`"  class="news_latest-news__container-img__coverImg">
                                    <div class="news_latest-news__container-img-icon" v-if="o.videoFlag"><img src="../../../static/images/icons/icon_video_play.png"></div>
                                </div>
                                <div class="news_latest-news__container-desc">
                                    <p class="news_latest-news__container-desc__title">{{o.title}}</p>
                                    <div>
                                        <span class="news_latest-news__container-desc_tag">{{o.publishedOn}}</span>
                                    </div>
                                </div>
                            </router-link>  
                        </swiper-slide>                      						
                    </swiper> 
                      <!-- comment -->
                    <div class="mb-main">                
                        <comment :isGetData="refreshComment"></comment>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ToastrDesktop ref="toastrDesktop" :text="'LINK_COPIED'" ></ToastrDesktop>
    <!-- <Toastr ref="toastr" :text="'LINK_COPIED'" ></Toastr>  -->
</template>

<script>
import moment from 'moment'

import config from '@/js/config.js'
import MobileHeader from '@/components/mobile/Header.vue'
import ToastrDesktop from '@/components/toastr/ToastrDesktop.vue'
import Toastr from '@/components/toastr/Toastr.vue'
import Comment from '@/components/comment/Index.vue'

import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperCore, { Pagination,Navigation,Autoplay} from 'swiper/core';
SwiperCore.use([Pagination,Navigation,Autoplay]);

import {mapGetters,mapActions} from 'vuex'

export default {
    components:{
        Swiper, 
        SwiperSlide,  
		MobileHeader,
        ToastrDesktop,
        Toastr,
        Comment
    },
    data() {
    
        return {
            projectName: config.projectName,
            s3ImgUrl: config.s3ImgUrl,
            socialMediaShare: [
                {
                    id: "fb",
                    socialMediaUrl: 'https://www.facebook.com/sharer/sharer.php?u=',
                    iconUrl:'icon_fb'
                },
                {    
                    id: "twitter",
                    socialMediaUrl: 'https://twitter.com/share?url=',
                    iconUrl:'icon_twitter'
                },
                {    
                    id: "line",
                    socialMediaUrl: 'https://social-plugins.line.me/lineit/share?url=',
                    iconUrl:'icon_line'
                },
                {    
                    id: "selfShare",
                    socialMediaUrl: "",
                    iconUrl:'icon_share'
                }
            ],
            newsDetail: {
                //ssr bugs must initial some value
                urlAuthorName:'1',
                author:'1'
            },
                
            relatedArticleList: [],   
            refreshComment: false,       
        }
    },
    created() {
        this.newsId = this.$route.params.id
    },
   	watch:{
		$route() {
            if (this.$route.name === 'newsDetail') {
                this.init();               
            } 
        },
	},
    computed:{
        ...mapGetters([              
                'isMobile',
                'currentLocale',
                'currentSportType',
           ]),
    },
    mounted() {
//         let card = document.querySelector('.card');

// document.addEventListener('mousemove', function(e) {
//   let xAxis = (window.innerWidth / 2 - e.pageX) / 10;
//   let yAxis = (window.innerHeight / 2 - e.pageY) / 5;
//   card.style.transform = `rotateY(${xAxis}deg) rotateX(${yAxis}deg)`;
// });

        // let $card = document.querySelector('.news-detail__3d-wrapper');
        // let $g1 = document.querySelector('.news-detail__3d-1');
        // let $g2 = document.querySelector('.news-detail__3d-2');
        // let $g3 = document.querySelector('.news-detail__3d-3');
        // let   bounds = $card.getBoundingClientRect();
        // let that = this;
        //  $g1.style.transform = `scale(0.4) translate3d(0,0,150px)`;
        // document.addEventListener('mousemove', function(e) {
        //     const mouseX = e.clientX;
        //     const mouseY = e.clientY;
        //     const leftX = mouseX - bounds.x;
        //     const topY = mouseY - bounds.y;
        //     //console.log(topY)
        //     const center = {
        //         x: leftX - bounds.width / 2,
        //         y: topY - bounds.height / 2
        //     }
  
        //     const distance = Math.sqrt(center.x**2 + center.y**2);
        //     // $g3.style.transform =`translate( ${center.y / 100},
        //     //     ${-center.x / 100},)`
        //       let xAxis = (window.innerWidth / 2 - e.pageX) / 10;
        //     let yAxis = (window.innerHeight / 2 - e.pageY) / 5;
        //     let scale = that.getRandomNumberBetween(1,10)/10;
        //     if (scale <0.5) {
        //         scale = 0.5
        //     }
        //   //  $g3.style.transform = `translate3d(${xAxis/2}px,${yAxis/2}px,80px)`;
        //   //  $g1.style.transform = `translate3d(${xAxis}px,${yAxis}px,150px)`;
        //     //$card.style.transform = `rotateY(${xAxis}deg) rotateX(${yAxis}deg)`;
        //     $card.style.transform = `
        //         scale3d(1.07, 1.07, 1.07)
        //         rotate3d(
        //         ${center.y / 1},
        //         ${-center.x / 1},
        //         0,
        //         ${Math.log(distance)* 2}deg
        //         )
        //     `;
        //      // $g1.style.filter=`drop-shadow(${ that.getRandomNumberBetween(0,1)}px 4px 6px #eb6907)`
             
      


        // });
        //    document.addEventListener('scroll', function(e) {
        //     const leftX = window.pageYOffset - bounds.x;
        //     const topY = window.pageYOffset - bounds.y;
        //     const center = {
        //         x: leftX - bounds.width / 2,
        //         y: topY - bounds.height / 2
        //     }
        //      const distance = Math.sqrt(center.x**2 + center.y**2);
        //      let scale = window.pageYOffset/window.innerHeight *2;
        //      if (scale<0.5){
        //          scale = 0.4
        //      } else if (scale>1) {
        //          scale = 1
        //      }
        //       $g1.style.transform = `scale(${scale}) translate3d(0,0,150px)`;
        //        $g2.style.transform = `translate3d(0px,-${window.pageYOffset/6}px,80px)`;
        //     $card.style.transform = `
        //         scale3d(1.07, 1.07, 1.07)
        //         rotate3d(
        //         ${center.y * 100},
        //         ${-center.x * 100},
        //         0,
        //         ${Math.log(distance)* 2}deg
        //         )
        //     `;
        //    })
       
       
       this.init();
    },
    methods: {
        ...mapActions([
            "getNewsDetail",
            "getNewsListByTag",
            "toastrChangeEvent"
     
        ]),

        init() {
           this.getNewsDetailData();
           this.refreshComment = !this.refreshComment;
        },
        getRandomNumberBetween(min,max){
            return (Math.random()*(max-min+1)+min);
        },

        async getNewsDetailData(){
            this.newsId = this.$route.params.id;

            let params = {
                newsId: this.newsId
            }
            const result = await this.getNewsDetail(params);

            this.newsDetail = result.result;

            document.title = `${this.newsDetail.title} - ${this.projectName}`



           let metaTitle = document.querySelector("meta[property='og:title']")
           metaTitle.setAttribute('content', this.newsDetail.title);

        //      let metaDesc = document.querySelector("meta[property='og:description']")
        //    metaDesc.setAttribute('content', 'asdasd');

        //           let metaImage = document.querySelector("meta[property='og:image']")
                   
        //    metaImage.setAttribute('content', 'http://stg-img-tokyo.testsports168.com/uploadFile/504ea2fb-6af2-4cc6-a0fe-5269a620b17e.png');

            this.newsDetail.originalPublishedOn = result.result.publishedOn;
            //convert published date to xx ago
            this.newsDetail.publishedOn = moment(result.result.publishedOn).fromNow();

            //full url
            this.newsDetail.fullUrl = window.location.href;

            //author name replace space to '-'
            if (this.newsDetail.authorName) {
                this.newsDetail.urlAuthorName = this.newsDetail.authorName.toLowerCase().replace(/\s/g, '-')
            }

            //rename the tags name with '-' if the words got space
            this.newsDetail.newsTags.forEach(x=>{
                x.urlName = x.name.toLowerCase().replace(/\s/g, '-')
            })

            this.newsDetail.urlName = window.location.href; 

            //cover content string = html
            let wordsSplited =this.$tools.removeHTMLTags(this.newsDetail.content).replace(/['"]+/g, '').split(" ");
            wordsSplited.length = 200;  //only take max 200words
            let contentOnly = wordsSplited.join(" ");

            this.newsDetail.contentOnly =  contentOnly // only take max 200words
     
           //find all image src to array for google meta usage
            let div = document.createElement('div');
            div.innerHTML = this.newsDetail.content;
            let img = div.getElementsByTagName('img');
            let imgArray = [];

            for (let i = 0; i<img.length; i++) {
                imgArray.push(img[i].src);
            }

            this.newsDetail.contentImgArray = imgArray;

            //get related news list data
            this.getNewsListByTagData();
        },

        async getNewsListByTagData(){
       
             let newsTags = '';
             
             for (let i = 0; i<this.newsDetail.newsTags.length; i++) {
                 newsTags += `${this.newsDetail.newsTags[i].id},`;
               // newsTags.push(this.newsDetail.newsTags[i].id)
             }
    
             let params = {
                 pageNo: 1,
                 pageSize: 10, //requirement from AS-290
                 language: this.currentLocale,
                 tagIds: newsTags
             }
            const result = await this.getNewsListByTag(params);
   
            this.relatedArticleList = result.result.content;
          
                // this.relatedArticleList.push(result.result.content[0])
                //   this.relatedArticleList.push(result.result.content[1])

            this.relatedArticleList.forEach(x=>{
                x.urlName = x.title.toLowerCase().replace(/\s/g, '-')
                x.publishedOn = moment(x.publishedOn).fromNow();
            })
           
        },

        handleSocialMedia(o) {
            if (o.id === 'selfShare') {
                let value = window.location.href;
                /* Copy the text inside the text field */
                navigator.clipboard.writeText(value);
                if (this.isMobile) {
                    
                    let paramsToastr={
                        isOpen:true,
                        msg:'LINK_COPIED',   
                        info: ''   
                    }
                    this.toastrChangeEvent(paramsToastr)
                    //this.$refs.toastr.handleOpen(); 
                } else {
                    this.$refs.toastrDesktop.handleOpen(); 
                }
            } else {
                let href = window.location.href
                let url = o.socialMediaUrl + href;
            
                let windowOptions = "location=yes,height=570,width=420,scrollbars=yes,status=yes";

                //window.open(url,'_blank', windowOptions);

                this.$tools.popupwindow(url,'', 550, 570);
         
            }
        },

      
    },
}
</script>

<style >
.news-detail__wrapper{
    display: grid;
    grid-template-columns: 65% 35%;
    column-gap: 1rem;
    margin-top: 2rem;
    
}
.news-detail__title{
    font-size: 2.125rem;
    font-weight: 700;
    margin-bottom: 2.0625rem;
}
.news_detail__author-header__wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
}
.news_detail__author-wrapper{
    display: flex;
}
.news_detail__author-header__social-media{
    display: flex;
}
.news_detail__author-header__social-media__container{
    margin-right: 1.25rem;
    width: 1.25rem;
    height: 1.25rem;
    cursor: pointer;
}
.news_detail__author-icon{
    margin-right: .8rem;
    width: 2.0625rem;
    height: 2.0625rem;
}
.news_detail__author-name{
    font-size: 0.875rem;
}
.news_detail__author-tag{
    font-size: 0.6rem;
}
.news_detail__author-tag::after{
    content: '|';
    width: .1rem;
    height: 1rem;
    margin: 0 .5rem;
}
.news_detail__author-tag:last-of-type::after {
    display: none;
}
.news-detail__content{
    overflow: hidden;
}
.news-detail__content img{
    max-width: 100%;
}
.news-detail__content video{
    max-width: 100%;
    height: auto;
}
.news-detail__content iframe{
    display: flex;
    max-width: 100% !important; 
    width:100%;
    height: 445px !important;
    border: none;
}
/* tag */
.news-detail__tags-wrapper{
    margin-bottom: 3.31rem;
}
.news-detail__tags-title__wrapper{
    border-bottom: .1rem solid var(--color-grey-60);
    padding-bottom: 1rem;
    margin-bottom: 1rem;
}
.news-detail__tags{
    display: inline-block;
    background-color: var(--color-grey-60);
    padding: .4rem .5rem;
    font-size: 0.6875rem;
    border-radius: .4rem;
    margin-right: .5rem;
    margin-bottom: .8rem;
    cursor: pointer;
}

/* related news */
.news_latest-news__title-wrapper{
	display: flex;
	justify-content: space-between;
	border-bottom: .1rem solid var(--color-grey-60);
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
}
.news_latest-news__title{
	font-size: 0.875rem;
	font-weight: 700;
}

.news_latest-news__container{
	display: flex;
	padding-bottom: 1rem;
	border-bottom: .1rem solid var(--color-grey-60);
    margin-bottom: 1rem;
		
}
.news_latest-news__container-img{
	margin-right: 1.5rem;
	position: relative;
    min-width: 11.625rem;
    height: 6.563rem;
}
.news_latest-news__container-img__coverImg{
    object-fit: cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
.news_latest-news__container-img-icon{
    position: absolute;
    bottom: 0.67rem;
    left: 0.67rem;
    width: 1.87rem;
    height: 1.87rem;
}
.news_latest-news__container-img-icon img{
    width: 100%;
    height: 100%;
}
.news_latest-news__container-tag__wrapper{
    margin: .3rem 0;
}
.news_latest-news__container-tag{
    display: inline-block;
    background-color: var(--color-grey-60);
    padding: .4rem .5rem;
    font-size: 0.6875rem;
    border-radius: .4rem;
    margin-right: .5rem;
}
.news_latest-news__wrapper{
	display: grid;
    row-gap: 1rem
}
.news_latest-news__container-desc{
	padding: 1rem 1rem 1rem 0;
	display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}
.news_latest-news__container-desc__title{
    font-weight: 700;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.875rem;
}
.news_latest-news__container-desc_tag{
	font-size: 0.6rem;
}
.news_latest-news__container-desc_tag::after{
	content: '|';
	width: .1rem;
	height: 1rem;
	margin: 0 .5rem;
}
.news_latest-news__container-desc_tag:last-of-type::after {
	display: none;
}
.news-detail__3d-wrapper{
      position: relative;
    width: 35rem;
    height: 23rem;
    /* background-color: #35454d; */
    transform-style: preserve-3d;
}
.news-detail__3d{
    position: absolute;
    transition: all .3s;
        transform-style: preserve-3d;
}
.news-detail__3d img{
    width: 100%;
}
.news-detail__3d-1{
       transform: translateZ(100px);
 
}
.news-detail__3d-2{
   transform: translateZ(150px);
}
.news-detail__3d-3{
   transform: translateZ(50px);
}

.card {
  width: 400px;
  height: 200px;
  position: relative;
  background: #00BCD4;
  border-radius: 3px;
  background-position: top center;
  transform-style: preserve-3d;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size:1rem
}
.card:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 8px;
    border-radius: 50%;
    left: 0;
    bottom: 0px;
    box-shadow: 0 30px 20px rgb(0 0 0 / 30%);
}
.card-content {
  transform-style: preserve-3d;
  text-align: center;
    font-size: 1.5rem;

}
 .card-content h1 {
    color: #FFF;
    transform: translateZ(100px);
  }
  .card-content p {
    color: #FFF;
    transform: translateZ(50px);
  }
@media (max-width: 768px){
    .news-detail__3d-wrapper{
        width: 100%;
    }
    .news-detail__wrapper{
        display: flex;
        flex-direction: column;
    }
    .news-detail__title{
        font-size: 1.125rem;
    }
    .news_detail__author-name{
        font-weight: 700;
        margin-bottom: .2rem;
        display: block;
    }
    .news_latest-news__container{
        flex-direction: column;
        border-bottom: none;
    }
    .news_latest-news__container-desc{
        padding: 0.5rem 1rem;
        background-color: var(--color-theme)
    }
    .news_latest-news__container-desc__title{
        height: 2rem;
        font-weight: normal;
    }
    .news-detail__tags-wrapper{
        margin-bottom: 0.51rem;
    }
    .news_latest-news__title-wrapper{
        margin-bottom: 0;
        border-bottom: none;
    }
    .news_latest-news__container-img{
        margin-right: 0;
        width: auto;
    }
    .news-detail__content iframe{
        height: 53vw !important;
    }
}

</style>