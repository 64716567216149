<template>
   <div class="comment-container" >
        <div class="comment-img__wrapper">
            <img class="w-100 h-100" :src="`${s3ImgUrl}/${dataList.profilePicture}`">
        </div>
        <div class="comment-content">
            <div class="comment-header">
                <span>{{dataList.displayName}}</span>
                <span>{{dataList.displayDate}}</span>
            </div>
            <div class="comment-body">
                <p>{{dataList.content}}</p>
            </div>
            <div class="comment-footer" v-if="isShowFavReply">
                <div class="mr-1rem display-flex pointer" @click="likeComment()">
                    <span class="comment-footer__fav-icon">
                        <img v-if="dataList.rate ==='LIKE'" src="../../../static/images/icons/icon_love_orange_selected.png">
                         <img v-else src="../../../static/images/icons/icon_love_orange.png">
                    </span>
                    <span>{{dataList.likeCount}}</span>
                </div>
                <span class="pointer" v-if="isShowReply" @click="reply(dataList)">{{$t('REPLY')}}</span>                   
            </div>
        </div>
    </div>
         <!-- <CommentReply class="mb-1rem"></CommentReply> -->
</template>

<script>
import CommentReply from '@/components/comment/Reply.vue'

import config from '@/js/config.js'
import moment from 'moment'

import {mapGetters,mapActions} from 'vuex'
export default {
    components: {
        CommentReply
    },

    props: {
        dataList: {
            type : Object,
        },
        parentData: {
            type : Object
        },
        isShowFavReply: {
            type: Boolean,
            default: true
        },
        isShowReply: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
                s3ImgUrl: config.s3ImgUrl,
                pageSizeChildComment: config.pageSizeChildComment
        }
    },
    computed:{
        dataList(e) {
            let dataList = this.dataList;
            if (typeof dataList !== 'undefined') {

            }
            dataList.displayDate = moment(dataList.createTime).fromNow(); 
            
            return dataList
      }
    },
    mounted() {
        this.newsId = this.$route.params.id 
    },
    methods: {
         ...mapActions([
            "postNewsLikeComment",
            "getNewsListComment",
            "commentListChildEvent"
        ]),

        async reply() {     
            this.dataList.isShowChildComment = !this.dataList.isShowChildComment;
       
            if (this.dataList.isShowChildComment) {
                let params = {
                    newsId: this.newsId,
                    size: this.pageSizeChildComment,
                    newsCommentParentId: this.dataList.newsCommentId
                }

                const result = await this.getNewsListComment(params);
                
                let returnResult = result.result;
                returnResult.newsCommentParentId = this.dataList.newsCommentId;

                this.commentListChildEvent(returnResult); 
            }  
        },

        async likeComment() {
            let rate = this.dataList.rate;
            let paramRate = 'LIKE';

            //rate: LIKE, NEUTRAL (When user click like again to cancel)
            if (rate === 'LIKE') {
                paramRate = 'NEUTRAL'
            }

            let params = {
                newsCommentId: this.dataList.newsCommentId,
                rate: paramRate
            }

            const result = await this.postNewsLikeComment(params);
            
            if (paramRate === 'LIKE') {
                this.dataList.rate = "LIKE";
                this.dataList.likeCount++;
            } else {
                 this.dataList.rate = "NEUTRAL";
                this.dataList.likeCount--;
            }
           
        }
    },
}
</script>

<style>
.comment-container{
    border-radius: 0.5rem;
    padding: 1rem;
    background-color: var(--color-theme);
    display: flex;
}
.comment-img__wrapper{
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 1rem;
}
.comment-content{
    flex: 1;
}
.comment-header{
    font-size: 0.875rem;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
}
.comment-body{
    font-size: 0.6875rem;
    margin-bottom: 0.75rem;
}
.comment-footer{
    display: flex;

}
.comment-footer__fav-icon{
    margin-right: .3rem;
}
</style>