<template>
  <div>
    <div class="comment-reply__wrapper">
        <div class="comment-reply__avatar">
            <img :src="`${s3ImgUrl}/${userInfo.profilePicture}`" class="w-100 h-100">
        </div>
        <div class="flex-1 mr-1rem" ><input class="comment-reply__input" :placeholder="$t('ADD_A_COMMENT')" v-model="inputReply" @keydown="listenKeypressEvent" v-on:keyup="inputReplyEvent"></div>
        
        <div class="pointer" @click="sendComment">
            <img v-if="isAbleToSent" src="../../../static/images/icons/icon_comment_sent_colored.png">
            <img v-else src="../../../static/images/icons/icon_comment_sent.png">          
        </div>
    </div>
</div>
</template>

<script>
import {mapGetters,mapActions} from 'vuex'
import config from '@/js/config.js'
export default {
    props: {
        parentData: {
            type: Object,
        }
    },
    data() {
        return {
            s3ImgUrl: config.s3ImgUrl,
            inputReply: '',

            isAbleToSent: false
        }
    },
     created() {
          this.init();
    },
    watch: {
        $route() {
            if (this.$route.name === 'newsDetail') {
                this.init();
            }
        }
    },
     computed: {
        ...mapGetters([
            "userInfo"
        ]),
    },
    methods: {
          ...mapActions([
            "commentListEvent",
            "postNewsAddComment",
            "getNewsListComment",
            "commentListPushEvent"
        ]),

        init() {
            this.newsId = this.$route.params.id;
            this.inputReply = '';
        },

        listenKeypressEvent(e) {
             if (e.key === "Enter") {
               this.sendComment();
            } 
        },

        inputReplyEvent() {
            if (this.inputReply.length > 0) {
                this.isAbleToSent = true;
            } else {
                 this.isAbleToSent = false;
            }
        },

        async sendComment() {
            if (!this.isAbleToSent) {
                return;
            }
            let params = {
                content: this.inputReply.toString(),
                newsId: this.newsId,
            }
          
           //child reply
            if (typeof this.parentData !== 'undefined') {
                 if (this.parentData.newsCommentId) {
                    params.newsCommentParentId = this.parentData.newsCommentId;
                }
            }
            const result = await this.postNewsAddComment(params);


            //get the latest comment 
             let paramsNewsList = {
                newsId: this.newsId,
                size: 1
            }

            if (typeof this.parentData !== 'undefined') {
                //child reply                
                paramsNewsList.newsCommentParentId = this.parentData.newsCommentId;
                const newsListResult = await this.getNewsListComment(paramsNewsList);

                let latestComment = newsListResult.result;
                latestComment.parentData = this.parentData.newsCommentId;

                this.commentListPushEvent(latestComment);
               
            } else {
                //parent reply
                const newsListResult = await this.getNewsListComment(paramsNewsList);
                
                let latestComment = newsListResult.result;

                this.commentListPushEvent(latestComment);
            }
            this.inputReply = "";   
            this.inputReplyEvent();
        },

           async getCommentListData() {
            let params = {
                newsId: this.newsId,
                size: 5
            }
            
            const result = await this.getNewsListComment(params);
            //this.commentListEvent( result.result.comments);           
        }
    },
}
</script>

<style>
.comment-reply__wrapper{
    display: flex;
    align-items: center;
    background-color: var(--color-theme);
    padding: 1rem;
    border-radius: 0.5rem;
}
.comment-reply__avatar{
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 1rem;
}
.comment-reply__input{
    background-color: var(--color-grey-60);
    border: none;
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    border-radius: 0.5rem;
    width: 100%;
    box-sizing: border-box;
    color: var(--color-grey-light);
    border: #546066 .1rem solid;
}
.comment-reply__input:focus-visible{
    outline: none;
}
</style>